<template>
  <a-button class="editable-add-btn" type="primary" @click="openModal" style="margin-bottom: 16px">Добавить контент</a-button>
  <a-modal
    class="section-create-modal"
    title="Добавить контент"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="section" layout="vertical">
      <div class="col-md-12">
        <a-form-item label="Тип контента">
          <a-select
            ref="sectionType"
            placeholder="Выберите тип контента"
            v-model:value="section.type"
            :options="options"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="Контент">
          <a-input
            placeholder="Введите контент"
            v-model:value="section.content"
          />
        </a-form-item>
        <a-form-item label="Цвет">
          <a-select
            placeholder="Выберите цвет"
            v-model:value="section.color"
          >
            <a-select-option value="#595c97">Default</a-select-option>
            <a-select-option value="#00000073">Secondary</a-select-option>
            <a-select-option value="#43a37a">Success</a-select-option>
            <a-select-option value="#d89a21">Warning</a-select-option>
            <a-select-option value="#d03133">Error</a-select-option>
            <a-select-option value="#4b7cf3">Link</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Ширина блока">
          <a-input
            v-model:value="section.width"
            placeholder="Введите ширину"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick} from 'vue';
import apiClient from "@/services/axios";
import {useRoute} from "vue-router";

const emit = defineEmits(['reload']);

let loading = ref(false),
  options = ref([
    {
      value: null,
      label: 'Выберите тип'
    },
    {
      value: 'title',
      label: 'Заголовок'
    },
    {
      value: 'blockquote',
      label: 'Цитата'
    },
    {
      value: 'paragraph',
      label: 'Параграф'
    },
    {
      value: 'keyboard',
      label: 'Клавиатура'
    },
    {
      value: 'code',
      label: 'Код'
    },
    {
      value: 'pre',
      label: 'JSON'
    },
    {
      value: 'strong',
      label: 'Жирный'
    },
    {
      value: 'ol_list',
      label: 'Нумерация'
    },
    {
      value: 'ul_list',
      label: 'Список'
    },
    {
      value: 'image',
      label: 'Изображение'
    },
    {
      value: 'table',
      label: 'Таблица'
    }
  ]),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  sectionType = ref("sectionType"),
  section = reactive({
    content: '',
    type: null,
    color: null,
    width: null,
  });

const
  route = useRoute(),
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => sectionType.value?.focus());
  },
  resetForm = () => {
    Object.assign(section, {
      type: '',
      content: '',
    });
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(section);

    return apiClient
      .post(`/integrations/section-create?integration=${route.params.page}`, model)
      .then(function (response) {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      })
      .catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        // console.log(err)
      })
      .finally(() => {
        confirmLoading.value = false;
        emit('reload')
      })
  }
</script>

<style scoped>
</style>
