<template>
  <a-modal
    class="section-edit-modal"
    title="Редактировать"
    :visible="modalVisible"
    :closable="true"
    :width="1000"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="section" layout="horizontal">
      <div class="col-md-12">
        <a-form-item label="Тип контента">
          <a-select
            ref="sectionType"
            placeholder="Выберите тип контента"
            v-model:value="section.type"
            :options="options"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="Контент">
          <div v-if="['ol_list', 'ul_list'].includes(section.type)">
            <a-form-item v-for="(item, key) in JSON.parse(section.content)" :key="key" :label="key + 1 + ':'">
              <a-input :value="item" @input="updateItem($event, key)" />
              <a-typography-link
                @click.prevent="deleteItem(key)"
                class="float-right text-danger py-2"
              ><i class="fe fe-trash"></i> Удалить пункт</a-typography-link>
            </a-form-item>
            <a-typography-link @click.prevent="addItem">Добавить пункт</a-typography-link>
          </div>
          <ckeditor
            v-else-if="['table'].includes(section.type)"
            :editor="editor"
            v-model="section.content"
            :config="editorConfig"
          ></ckeditor>
          <a-input
            v-else-if="section.type === 'title'"
            placeholder="Введите заголовок"
            v-model:value="section.content"
          />
          <a-textarea
            v-else
            placeholder="Введите контент"
            v-model:value="section.content"
          >{{ section.content }}</a-textarea>
        </a-form-item>
        <a-form-item label="Цвет">
          <a-select
            placeholder="Выберите цвет"
            v-model:value="section.color"
          >
            <a-select-option value="#595c97">Default</a-select-option>
            <a-select-option value="#00000073">Secondary</a-select-option>
            <a-select-option value="#43a37a">Success</a-select-option>
            <a-select-option value="#d89a21">Warning</a-select-option>
            <a-select-option value="#d03133">Error</a-select-option>
            <a-select-option value="#4b7cf3">Link</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Ширина блока">
          <a-input
            v-model:value="section.width"
            placeholder="Введите ширину"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import apiClient from "@/services/axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const props = defineProps({
  sectionId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);
let loading = ref(true),
  options = ref([
    {
      value: null,
      label: 'Выберите тип'
    },
    {
      value: 'title',
      label: 'Заголовок'
    },
    {
      value: 'blockquote',
      label: 'Цитата'
    },
    {
      value: 'paragraph',
      label: 'Параграф'
    },
    {
      value: 'keyboard',
      label: 'Клавиатура'
    },
    {
      value: 'code',
      label: 'Код'
    },
    {
      value: 'pre',
      label: 'JSON'
    },
    {
      value: 'strong',
      label: 'Жирный'
    },
    {
      value: 'ol_list',
      label: 'Нумерация'
    },
    {
      value: 'ul_list',
      label: 'Список'
    },
    {
      value: 'image',
      label: 'Изображение'
    },
    {
      value: 'table',
      label: 'Таблица'
    }
  ]),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  editor = ref(ClassicEditor),
  editorConfig = ref({
  }),
  sectionType = ref("sectionType"),
  section = reactive({
    content: '',
    type: '',
    color: null,
    width: null,
    integration_id: '',
  });

const
  resetForm = () => {
    Object.assign(section, {
      content: '',
      type: '',
      integration_id: '',
      width: '',
      color: ''
    });
    modalVisible.value = false;
    emit('hide-modal')
  },
  addItem = () => {
    let content = JSON.parse(section.content)
    content.push('')
    section.content = JSON.stringify(content)
  },
  deleteItem = (key) => {
    let content = JSON.parse(section.content)
    delete content[key]
    content.splice(key, 1)
    section.content = JSON.stringify(content)
  },
  updateItem = (event, key) => {
    let content = JSON.parse(section.content)
    content[key] = event.target.value
    section.content = JSON.stringify(content)
  },
  update = () => {
    confirmLoading.value = true;
    let model = toRaw(section)
    return apiClient
      .put(`/integrations/section-update?id=${props.sectionId}`, model)
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        emit('hide-modal')
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/integrations/section-view?id=${props.sectionId}`)
  .then(response => {
    if (response) {
      Object.assign(section, {
        content: response.data.data.content,
        type: response.data.data.type,
        integration_id: response.data.data.integration_id,
        width: response.data.data.width,
        color: response.data.data.color
      })
    }
  }).catch(() => {
    loading.value = false
  })
  .finally(() => {
    loading.value = false
  })

onMounted(() => nextTick(() => sectionType.value?.focus()))
</script>

<style scoped></style>
