<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <vb-order-section @reload="reload()" :tree="treeData" />&nbsp;<vb-create-section @reload="reload()" />
      </div>
    </div>
    <vb-edit-section
      v-if="editModal"
      :section-id="sectionId"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #type="{ record }">
        {{ types.find(type => type.value === record.type )?.label }}
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #content="{ record }">
        <div v-if="record.type === 'title'"><a-typography-title :level="4">{{ record.content }}</a-typography-title></div>
        <div v-else-if="record.type === 'blockquote'"><a-typography-paragraph><blockquote>{{ record.content }}</blockquote></a-typography-paragraph></div>
        <div v-else-if="record.type === 'strong'"><strong>{{ record.content }}</strong></div>
        <div v-else-if="record.type === 'code'"><a-typography-text code><span :style="{ color: record.color ? record.color : '#595c97' }">{{ record.content }}</span></a-typography-text></div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-else-if="record.type === 'table'" v-html="record.content"></div>
        <!--eslint-enable-->
        <div v-else-if="record.type === 'pre'"><code :style="{ color: record.color ? record.color : '#595c97' }" style="white-space: pre">{{ JSON.parse(record.content) }}</code></div>
        <div v-else-if="record.type === 'keyboard'"><a-typography-paragraph keyboard><span style="color: #595c97">{{ record.content }}</span></a-typography-paragraph></div>
        <div v-else-if="record.type === 'ol_list'">
          <ol>
            <li v-for="(item, key) in JSON.parse(record.content)" :key="key">{{ item }}</li>
          </ol>
        </div>
        <div v-else-if="record.type === 'ul_list'">
          <ul>
            <li v-for="(item, key) in JSON.parse(record.content)" :key="key">{{ item }}</li>
          </ul>
        </div>
        <div v-else-if="record.type === 'image'">
          <img :src="record.content" height="50" alt="">
        </div>
        <div v-else>{{ record.content }}</div>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, reactive, ref, toRefs, onMounted } from 'vue';
import VbCreateSection from "@/views/integration/section/create";
import VbOrderSection from "@/views/integration/section/order";
import VbEditSection from "@/views/integration/section/edit";
import { useRoute, useRouter } from 'vue-router';
import apiClient from "@/services/axios";
import { message, Modal } from 'ant-design-vue'

let
  dataSource = ref([]),
  loading = ref(false),
  editModal = ref(false),
  total = ref(0),
  types = ref([
    {
      value: null,
      label: 'Выберите тип'
    },
    {
      value: 'title',
      label: 'Заголовок'
    },
    {
      value: 'blockquote',
      label: 'Цитата'
    },
    {
      value: 'paragraph',
      label: 'Параграф'
    },
    {
      value: 'keyboard',
      label: 'Клавиатура'
    },
    {
      value: 'code',
      label: 'Код'
    },
    {
      value: 'pre',
      label: 'JSON'
    },
    {
      value: 'strong',
      label: 'Жирный'
    },
    {
      value: 'ol_list',
      label: 'Нумерация'
    },
    {
      value: 'ul_list',
      label: 'Список'
    },
    {
      value: 'image',
      label: 'Изображение'
    },
    {
      value: 'table',
      label: 'Таблица'
    }
  ]),
  sectionId = ref(0);

const router = useRouter(),
  route = useRoute(),
  treeData = ref([]),
  initialParams = {
    page: 1,
    'per-page': 50,
    sort: '',
    type: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Номер',
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      width: 100,
      slots: {
        customRender: 'order',
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      slots: {
        customRender: 'type',
      },
    },
    {
      title: 'Контент',
      dataIndex: 'content',
      key: 'content',
      sorter: true,
      slots: {
        customRender: 'content',
      },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showEditModal = (id) => {
    sectionId.value = id
    editModal.value = true
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление записи',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        return apiClient.delete(`/integrations/section-delete?id=${id}`).then(response => {
          if (response) {
            message.success('Запись успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get(`/integrations/sections?integration=${route?.params?.page}`, {
      params,
    }).then(({data}) => {
      dataSource.value = data.data.items
      if (data.data.items.length > 0) {
        treeData.value = []
        data.data.items.forEach((item) => {
          treeData.value.push({
            content: item.content.substring(0, 10),
            type: item.type,
            i: item.id
          })
        })
      }
      total.value = data.data.total
      loading.value = false
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };
onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'sections',
    params: {
      page: route.params.page,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped></style>
